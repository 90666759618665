import { ModalType } from '../../types';
import { CreateActionParams } from '../actions';

export type ShowModal = <T>(type: ModalType, props?: T) => void;

export function createShowModalAction({
  getControllerState,
}: CreateActionParams): ShowModal {
  return (type, props) => {
    const [, setState] = getControllerState();

    setState({ modalContext: { type, props } });
  };
}
