import { bookingsMemberAreaListUpload } from '@wix/bi-logger-wixboost-ugc/v2';
import { Bookings, TabTypes } from '../../../types';
import { BiLogger } from '../../../core/bi/biLoggerFactory';

export interface LogTabLoadedBiArgs {
  biLogger?: BiLogger;
  tabName: TabTypes;
  data: Bookings;
}

export const logTabLoadedBi = ({
  tabName,
  data,
  biLogger,
}: LogTabLoadedBiArgs) => {
  biLogger?.report(
    bookingsMemberAreaListUpload({
      isEmptyState: !data.groups.length,
      reschedule: data.groups.some((group) => group.allowedActions.reschedule),
      tabName,
    }),
  );
};
