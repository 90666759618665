import { Order } from '@wix/ambassador-ecom-v1-order/types';
import { ApiContext } from '../../types';
import { QueryExtendedBookingResponse } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import _ from 'lodash';

export interface GetOrdersArgs {
  bookings: QueryExtendedBookingResponse;
}

export const getOrders = async ({
  api,
  bookings,
}: ApiContext & GetOrdersArgs): Promise<{
  orders?: Order[];
}> => {
  const allBookingIds = bookings.extendedBookings?.map((booking) => {
    return {
      bookingId: booking.booking?.id,
      groupId:
        booking.booking?.multiServiceBookingInfo?.id || booking.booking?.id!,
    };
  });
  const bookingIds = _.uniqBy(allBookingIds, (booking) => booking.groupId).map(
    (booking) => booking.groupId,
  );
  const orders = (await api.queryOrders({ bookingIds })).orders;

  return { orders };
};
