import { ActionsContext } from '../../core/actionsContext/contextFactory';
import { BookingStatus } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { ApplicationState, ApplicationStatus, TabTypes } from '../../types';
import { mockUserInfo } from '../../../__tests__/mocks/mockUserInfo';
import { mockSelectedTimezone } from '../../../__tests__/mocks/mockSelectedTimezone';
import { mockEnrichedBookings } from '../../../__tests__/mocks/mockEnrichedBookings';
import { mockSelectedTab } from '../../../__tests__/mocks/mockSelectedTab';
import { mockGroup } from '../../../__tests__/mocks/mockGroup';
import { mockService } from '../../../__tests__/mocks/mockService';
import BookingBuilder from '../../../__tests__/mocks/mockBooking';
import StaffBuilder from '../../../__tests__/mocks/mockStaff';
import { mockBusinessInfo } from '../../../__tests__/mocks/mockBusinessInfo';

export const createEmptyDummyState = (): ApplicationState => {
  const { bookings, selectedTimezone, businessInfo } = mockEnrichedBookings({
    bookings: { groups: [] },
    selectedTimezone: mockSelectedTimezone(),
    businessInfo: mockBusinessInfo(),
  });

  return {
    status: ApplicationStatus.IDLE,
    selectedTab: mockSelectedTab({ type: TabTypes.UPCOMING }),
    environmentModes: {
      isSSR: false,
      isEditorMode: true,
      isPreviewMode: false,
      isSiteMode: false,
    },
    userInfo: mockUserInfo(),
    businessInfo,
    selectedTimezone,
    upcomingData: bookings,
  };
};

export const createUpcomingDummyState = ({
  flowAPI,
}: ActionsContext): ApplicationState => {
  const { t } = flowAPI.translations;
  const staffMember = t('app.my-bookings-widget.dummy.staff-member');
  const serviceName = t('app.my-bookings-widget.dummy.service-name');
  const serviceAddress = t('app.my-bookings-widget.dummy.service-address');

  const relatedStaff = StaffBuilder().buildWithName(staffMember).build();
  const groups = [
    mockGroup({
      groupId: '1',
      bookings: [
        BookingBuilder()
          .withService(mockService({ name: serviceName }))
          .withVideoConference({ link: 'wix.com' })
          .withLocationInfo(serviceAddress)
          .build(),
      ],
    }),
    mockGroup({
      groupId: '2',
      bookings: [
        BookingBuilder()
          .withVideoConference({ link: 'wix.com' })
          .withLocationInfo(serviceAddress)
          .withStaffInfo(relatedStaff)
          .withCancelAllowed()
          .withBookAnotherAllowed()
          .withRescheduleAllowed()
          .withService(mockService({ name: `${serviceName} 1` }))
          .build(),
        BookingBuilder()
          .withVideoConference({ link: 'wix.com' })
          .withStaffInfo(relatedStaff)
          .withService(mockService({ name: `${serviceName} 2` }))
          .withLocationInfo(serviceAddress)
          .build(),
      ],
    }),
    mockGroup({
      groupId: '4',
      bookings: [
        BookingBuilder()
          .withService(mockService({ name: serviceName }))
          .withVideoConference({ link: 'wix.com' })
          .withLocationInfo(serviceAddress)
          .build(),
      ],
    }),
    mockGroup({
      groupId: '5',
      bookings: [
        BookingBuilder()
          .withService(mockService({ name: serviceName }))
          .withStatus(BookingStatus.WAITING_LIST)
          .withLocationInfo(serviceAddress)
          .build(),
      ],
    }),
  ];

  const { bookings, selectedTimezone, businessInfo } = mockEnrichedBookings({
    bookings: { groups },
    selectedTimezone: mockSelectedTimezone(),
    businessInfo: mockBusinessInfo(),
  });

  return {
    status: ApplicationStatus.IDLE,
    selectedTab: mockSelectedTab({ type: TabTypes.UPCOMING }),
    environmentModes: {
      isSSR: false,
      isEditorMode: true,
      isPreviewMode: false,
      isSiteMode: false,
    },
    businessInfo,
    selectedTimezone,
    userInfo: mockUserInfo(),
    upcomingData: bookings,
  };
};
