import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BOOKINGS_APP_DEF_ID } from '../consts/applicationIdentities';
import { ApiContext } from './types';
import { CancelSingleArgs, cancelSingle } from './implementations/cancelSingle';
import {
  cancelWaitlist,
  CancelWaitlistArgs,
} from './implementations/cancelWaitlist';
import { LeaveResponse } from '@wix/ambassador-bookings-v1-waiting-list/types';
import { CancelGroupArgs, cancelGroup } from './implementations/cancelGroup';
import { isSSR } from './implementations/isSSR';
import { GetAggregatedInfoResponse } from '@wix/ambassador-bookings-catalog-v1-business-info/types';
import { getBusinessInfo } from './implementations/getBusinessInfo';
import {
  ListResourcesByTagNameArgs,
  listResourcesByTagName,
} from './implementations/listResourcesByTagName';
import { getEnvironmentModes } from './implementations/environmentModes';
import {
  CancelBookingResponse,
  CancelMultiServiceBookingResponse,
} from '@wix/ambassador-bookings-v2-booking/types';
import {
  queryEcomOrders,
  QueryEcomOrdersArgs,
} from './implementations/queryOrders';
import {
  getEnrichedBookings,
  GetEnrichedBookingsArgs,
} from './implementations/getEnrichedBookings';
import { QueryOrderResponse } from '@wix/ambassador-ecom-v1-order/types';
import {
  queryBookings,
  QueryBookingsArgs,
} from './implementations/queryBookings';
import {
  queryServices,
  QueryServicesArgs,
} from './implementations/queryServices';
import {
  getGroupAllowedActions,
  GetGroupAllowedActionsArgs,
} from './implementations/getGroupAllowedActions';
import { getOrders, GetOrdersArgs } from './implementations/getOrders';
import {
  getListBookingFees,
  getListBookingFeesArgs,
} from './implementations/getListBookingFees';
import {
  logCancelBi,
  LogCancelBiArgs,
} from './implementations/logCancelBi/logCancelBi';
import {
  logTabLoadedBi,
  LogTabLoadedBiArgs,
} from './implementations/logTabLoadedBi/logTabLoadedBi';
import {
  getPurchasedPricingPlans,
  GetPurchasedPricingPlansArgs,
} from './implementations/getPurchasedPricingPlans';
import { Balance } from '@wix/ambassador-pricing-plans-v2-member-benefit/types';
import {
  getBookingById,
  GetBookingByIdArgs,
} from './implementations/getBookingById';
import { QueryExtendedBookingResponse } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { getCurrentUserContactId } from './implementations/getCurrentUserContactId';
import {
  getFormattedSubmission,
  GetFormattedSubmissionArgs,
} from './implementations/getFormattedSubmission';
import { FormattedFormSubmission } from '@wix/ambassador-forms-v4-submission/types';

export class Api {
  private readonly flowAPI: ControllerFlowAPI;

  constructor({ flowAPI }: { flowAPI: ControllerFlowAPI }) {
    this.flowAPI = flowAPI;
  }

  private getApiContext(): ApiContext {
    return {
      flowAPI: this.flowAPI,
      authorization: this.getAuthorization(),
      api: this,
    };
  }

  private getAuthorization() {
    const { controllerConfig } = this.flowAPI;
    return (
      (controllerConfig.wixCodeApi.site.getAppToken
        ? controllerConfig.wixCodeApi.site.getAppToken(BOOKINGS_APP_DEF_ID)
        : undefined) || controllerConfig.appParams.instance
    );
  }

  async cancelSingle(args: CancelSingleArgs): Promise<CancelBookingResponse> {
    return cancelSingle({
      ...args,
      ...this.getApiContext(),
    });
  }

  async cancelWaitlist(args: CancelWaitlistArgs): Promise<LeaveResponse> {
    return cancelWaitlist({
      ...args,
      ...this.getApiContext(),
    });
  }

  async cancelGroup(
    args: CancelGroupArgs,
  ): Promise<CancelMultiServiceBookingResponse> {
    return cancelGroup({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getBusinessInfo(): Promise<GetAggregatedInfoResponse> {
    return getBusinessInfo(this.getApiContext());
  }

  async listResourcesByTagName(args: ListResourcesByTagNameArgs) {
    return listResourcesByTagName({
      ...args,
      ...this.getApiContext(),
    });
  }

  async queryBookings(args: QueryBookingsArgs) {
    return queryBookings({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getListBookingFees(args: getListBookingFeesArgs) {
    return getListBookingFees({
      ...args,
      ...this.getApiContext(),
    });
  }

  async queryServices(args: QueryServicesArgs) {
    return queryServices({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getGroupAllowedActions(args: GetGroupAllowedActionsArgs) {
    return getGroupAllowedActions({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getEnrichedBookings(args: GetEnrichedBookingsArgs) {
    return getEnrichedBookings({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getOrders(args: GetOrdersArgs) {
    return getOrders({
      ...args,
      ...this.getApiContext(),
    });
  }

  async queryOrders(args: QueryEcomOrdersArgs): Promise<QueryOrderResponse> {
    return queryEcomOrders({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getBookingById(
    args: GetBookingByIdArgs,
  ): Promise<QueryExtendedBookingResponse> {
    return getBookingById({ ...args, ...this.getApiContext() });
  }

  async getPurchasedPricingPlans(
    args: GetPurchasedPricingPlansArgs,
  ): Promise<Balance[]> {
    return getPurchasedPricingPlans({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getCurrentUserContactId(): Promise<string> {
    return getCurrentUserContactId(this.getApiContext());
  }

  async getFormattedSubmission(
    args: GetFormattedSubmissionArgs,
  ): Promise<FormattedFormSubmission | undefined> {
    return getFormattedSubmission({ ...args, ...this.getApiContext() });
  }

  isSSR() {
    return isSSR(this.getApiContext());
  }

  getEnvironmentModes() {
    return getEnvironmentModes({
      ...this.getApiContext(),
    });
  }

  logCancelBi(args: LogCancelBiArgs) {
    return logCancelBi({
      ...args,
      ...this.getApiContext(),
    });
  }

  logTabLoadedBi(args: LogTabLoadedBiArgs) {
    return logTabLoadedBi({
      ...args,
      ...this.getApiContext(),
    });
  }
}
