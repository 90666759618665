import { ApplicationStatus } from '../../types';
import { CreateActionParams } from '../actions';
import { getUserInfo } from './implementations/getUserInfo';
import { createUpcomingDummyState } from '../../utils/createDummyState';
import { EventNames } from '../../core/fedops';
import { bookingsUoUFlowWidgetLoadSrc16Evid32 } from '@wix/bi-logger-bookings-data/v2';
import { widgetDefaults } from '../../core/bi/consts';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  context,
  getControllerState,
}: CreateActionParams): InitializeWidget {
  return async () => {
    const { fedopsLogger, biLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.WidgetInitialized);

    biLogger?.report(
      bookingsUoUFlowWidgetLoadSrc16Evid32({
        widget_name: widgetDefaults.widget_מame,
      }),
    );

    const [state, setState] = getControllerState();

    if (state.environmentModes.isEditorMode) {
      return setState(createUpcomingDummyState(context));
    }

    const userInfo = await getUserInfo(context);

    setState({
      status: ApplicationStatus.IDLE,
      userInfo,
    });

    fedopsLogger?.interactionEnded(EventNames.WidgetInitialized);
  };
}
