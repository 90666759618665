import { ApiContext } from '../../types';
import { leave, list } from '@wix/ambassador-bookings-v1-waiting-list/http';
import { LeaveResponse } from '@wix/ambassador-bookings-v1-waiting-list/types';

export interface CancelWaitlistArgs {
  bookingId: string;
  sessionId: string;
}

export const cancelWaitlist = async ({
  bookingId,
  sessionId,
  flowAPI,
}: CancelWaitlistArgs & ApiContext): Promise<LeaveResponse> => {
  const { httpClient } = flowAPI;

  const waitingList = await httpClient.request(
    list({ waitingResources: [sessionId] }),
  );

  if (!waitingList?.data?.list?.length) {
    throw new Error('No waiting list found');
  }

  const registrationId = waitingList?.data?.list[0]?.registrations?.find(
    (registration) => registration.bookingId === bookingId,
  )?.id;

  if (!registrationId) {
    throw new Error('No Registration Id found');
  }

  return (
    await httpClient.request(
      leave({ waitingResource: sessionId, registrationId }),
    )
  ).data;
};
