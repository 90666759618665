import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';
import { EventNames } from '../../core/fedops';
import { getUpdatedGroups } from '../../utils/bookInfo';

export type GetHistory = () => void;

export function createGetHistoryAction({
  context,
  getControllerState,
}: CreateActionParams): GetHistory {
  return async () => {
    const { api, fedopsLogger, biLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.HistoryTabLoaded);

    const [state, setState] = getControllerState();

    const { bookings, selectedTimezone, businessInfo } =
      await api.getEnrichedBookings({
        type: TabTypes.HISTORY,
        applicationState: state,
      });

    const isFirstLoad = !state.historyData;

    if (isFirstLoad) {
      api.logTabLoadedBi({
        tabName: TabTypes.HISTORY,
        data: bookings,
        biLogger,
      });
    }

    const groups = getUpdatedGroups(isFirstLoad, bookings, state.historyData!);

    setState({
      historyData: { groups, pagingMetadata: bookings.pagingMetadata },
      selectedTimezone,
      businessInfo,
    });

    fedopsLogger?.interactionEnded(EventNames.HistoryTabLoaded);
  };
}
