import { bulkGetMultiServiceBookingAllowedActions } from '@wix/ambassador-bookings-v2-booking/http';
import { BulkCalculateAllowedActionsResponse } from '@wix/ambassador-bookings-v2-booking/types';
import { ApiContext } from '../../types';

export interface GetGroupAllowedActionsArgs {
  groupIds: string[];
}

export const getGroupAllowedActions = async ({
  flowAPI,
  groupIds,
}: GetGroupAllowedActionsArgs &
  ApiContext): Promise<BulkCalculateAllowedActionsResponse> => {
  const { httpClient } = flowAPI;

  return (
    await httpClient.request<BulkCalculateAllowedActionsResponse>(
      bulkGetMultiServiceBookingAllowedActions({
        multiServiceBookingIds: groupIds,
      }),
    )
  ).data;
};
