import { CreateActionParams } from '../actions';
import { ToastContext } from '../../types';

export type ShowToast = (toastContext: ToastContext) => void;

export function createShowToastAction({
  getControllerState,
}: CreateActionParams): ShowToast {
  return (toastContext) => {
    const [, setState] = getControllerState();

    setState({ toastContext });
  };
}
