import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';
import { EventNames } from '../../core/fedops';
import { getUpdatedGroups } from '../../utils/bookInfo';

export type GetUpcoming = () => void;

export function createGetUpcomingAction({
  context,
  getControllerState,
}: CreateActionParams): GetUpcoming {
  return async () => {
    const { api, fedopsLogger, biLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.UpcomingTabLoaded);

    const [state, setState] = getControllerState();

    const { bookings, selectedTimezone, businessInfo } =
      await api.getEnrichedBookings({
        type: TabTypes.UPCOMING,
        applicationState: state,
        withBookingPolicySettings: true,
      });

    const isFirstLoad = !state.upcomingData;

    if (isFirstLoad) {
      api.logTabLoadedBi({
        tabName: TabTypes.UPCOMING,
        data: bookings,
        biLogger,
      });
    }
    const groups = getUpdatedGroups(isFirstLoad, bookings, state.upcomingData!);

    setState({
      upcomingData: { groups, pagingMetadata: bookings.pagingMetadata },
      selectedTimezone,
      businessInfo,
    });

    fedopsLogger?.interactionEnded(EventNames.UpcomingTabLoaded);
  };
}
