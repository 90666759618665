import { CreateActionParams } from '../actions';
import { SelectedTimezone } from '../../types';

export type SetSelectedTimezone = (args: SelectedTimezone) => Promise<void>;

export function createSetSelectedTimezoneAction({
  getControllerState,
}: CreateActionParams): SetSelectedTimezone {
  return async ({ timezone, type }) => {
    const [, setState] = getControllerState();

    setState({
      selectedTimezone: {
        type,
        timezone,
      },
    });
  };
}
