import { getAggregatedInfo } from '@wix/ambassador-bookings-catalog-v1-business-info/http';
import { ApiContext } from '../../types';
import { GetAggregatedInfoResponse } from '@wix/ambassador-bookings-catalog-v1-business-info/types';

export const getBusinessInfo = async ({
  flowAPI,
}: ApiContext): Promise<GetAggregatedInfoResponse> => {
  const { httpClient } = flowAPI;
  return (await httpClient.request(getAggregatedInfo({}))).data;
};
