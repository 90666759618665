import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Api } from './index';

export type ApiContext = {
  flowAPI: ControllerFlowAPI;
  authorization: string;
  api: Api;
};

export enum BusinessPropertyNames {
  CLIENT_CAN_CHANGE_TIMEZONE = 'clientCanChangeTimezone',
  DEFAULT_TIMEZONE_TYPE = 'defaultTimezone',
}
