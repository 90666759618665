import { SelectedTab } from '../../types';
import { CreateActionParams } from '../actions';
import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-wixboost-ugc/v2';

export type SetSelectedTab = (args: SelectedTab) => Promise<void>;

export function createSetSelectedTabAction({
  getControllerState,
  context,
}: CreateActionParams): SetSelectedTab {
  return async (selectedTab) => {
    const [, setState] = getControllerState();

    const { biLogger } = context;

    biLogger?.report(
      bookingsLoginUserAccountSeeAllServicesClick({
        action: `${selectedTab.type}-tab`,
      }),
    );

    setState({
      selectedTab,
    });
  };
}
