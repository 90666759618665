import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { CreateActionParams } from '../actions';
import { ConferencePlatform, MemberAreaClickActions } from '../../types';

export type ClickOnZoomLink = () => void;

export function createClickOnZoomLinkAction({
  context,
}: CreateActionParams): ClickOnZoomLink {
  return () => {
    const { biLogger } = context;

    biLogger?.report(
      bookingsLoginUserAccountSeeAllServicesClick({
        action: MemberAreaClickActions.OpenZoomLink,
        extraInfo: JSON.stringify({
          current_platform: ConferencePlatform.ZOOM,
        }),
      }),
    );
  };
}
