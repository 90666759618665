import { BookingsGroup } from '../../types';
import { CreateActionParams } from '../actions';

export type DeleteCancellationFeeInfo = ({
  group,
}: {
  group: BookingsGroup;
}) => void;

export function createDeleteCancellationFeeInfoAction({
  getControllerState,
}: CreateActionParams): DeleteCancellationFeeInfo {
  return async ({ group }) => {
    const [state, setState] = getControllerState();
    const groupFeeId = group?.groupId || group?.bookings[0]?.bookingId;

    setState({
      cancellationFeeInfo: {
        ...state.cancellationFeeInfo,
        [groupFeeId]: undefined,
      },
    });
  };
}
