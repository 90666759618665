import { CreateActionParams } from '../actions';
import { mapConfigToState } from '../../utils/mappers/settings-event-mapper';

export type OnConfigUpdate = (config: { [key: string]: any }) => void;

export function createOnConfigUpdateAction({
  context,
  getControllerState,
}: CreateActionParams): OnConfigUpdate {
  return (config) => {
    const [state, setState] = getControllerState();

    const updateState = mapConfigToState(config, state, context);

    setState(updateState);
  };
}
