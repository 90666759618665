import {
  PagingMetadataV2,
  Cursors,
} from '@wix/ambassador-bookings-reader-v2-extended-booking/types';

const validPagingMetadata = (): PagingMetadataV2 => {
  return {
    count: 0,
    offset: 0,
    total: 0,
    tooManyToCount: false,
    cursors: undefined,
    hasNext: false,
  };
};

export default function PagingMetadata() {
  let pagingMetadata = validPagingMetadata();
  return {
    build: () => {
      return {
        ...pagingMetadata,
      };
    },
    withCount(count: number) {
      pagingMetadata.count = count;
      return this;
    },
    withOffset(offset: number) {
      pagingMetadata.offset = offset;
      return this;
    },
    withTotal(total: number) {
      pagingMetadata.total = total;
      return this;
    },
    withTooManyToCount() {
      pagingMetadata.tooManyToCount = true;
      return this;
    },
    withNoTooManyToCount() {
      pagingMetadata.tooManyToCount = false;
      return this;
    },
    withCursors(cursors: Cursors) {
      pagingMetadata.cursors = cursors;
      return this;
    },
    withHasNext() {
      pagingMetadata.hasNext = true;
      return this;
    },
    withNoHasNext() {
      pagingMetadata.hasNext = false;
      return this;
    },
    buildWith(obj: Partial<PagingMetadataV2>) {
      pagingMetadata = { ...pagingMetadata, ...obj };
      return this;
    },
  };
}
