import { Service } from '@wix/ambassador-bookings-services-v2-service/types';

export const mockService = (service?: Partial<Service>): Service => {
  return service
    ? { name: 'service name', id: 'service-id', ...service }
    : {
        name: 'service name',
        id: 'service-id',
      };
};
