import { BookingsGroup } from '../../types';
import { getGroupSubmissionId } from '../../utils/bookInfo';
import { CreateActionParams } from '../actions';

export type GetSubmissionInfo = ({ group }: { group: BookingsGroup }) => void;

export function createGetSubmissionInfoAction({
  context,
  getControllerState,
}: CreateActionParams): GetSubmissionInfo {
  return async ({ group }) => {
    const [state, setState] = getControllerState();
    const { api } = context;
    const groupId = group?.groupId || group?.bookings[0]?.bookingId!;

    const submissionId = getGroupSubmissionId(group);
    if (!submissionId) {
      return;
    }

    const formattedSubmission = await api.getFormattedSubmission({
      submissionId,
    });

    if (!formattedSubmission) {
      return;
    }

    setState({
      submissionInfo: {
        ...state.submissionInfo,
        [groupId]: formattedSubmission,
      },
    });
  };
}
