import { ApiContext } from '../../types';
import { listBookingFeesByBookingIds } from '@wix/ambassador-bookings-fees-v1-booking-fee/http';
import { ListBookingFeesByBookingIdsResponse } from '@wix/ambassador-bookings-fees-v1-booking-fee/types';

export interface getListBookingFeesArgs {
  bookingIds: string[];
}

export const getListBookingFees = async ({
  bookingIds,
  flowAPI,
}: getListBookingFeesArgs &
  ApiContext): Promise<ListBookingFeesByBookingIdsResponse> => {
  const { httpClient } = flowAPI;

  return (
    await httpClient.request(
      listBookingFeesByBookingIds({
        bookingIds,
      }),
    )
  ).data;
};
