import { Balance } from '@wix/ambassador-pricing-plans-v2-member-benefit/types';
import { ApiContext } from '../../types';
import { getBalance } from '@wix/ambassador-pricing-plans-v2-member-benefit/http';

export interface GetPurchasedPricingPlansArgs {
  contactId: string;
}

export const getPurchasedPricingPlans = async ({
  contactId,
  flowAPI,
}: ApiContext & GetPurchasedPricingPlansArgs): Promise<Balance[]> => {
  const { httpClient } = flowAPI;

  try {
    return (
      (await httpClient.request(getBalance({ contactId }))).data.balanceItems ||
      []
    );
  } catch (e) {
    console.error('Failed to get purchased pricing plans', e);
    return [];
  }
};
