import { navigateToServiceList } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { CreateActionParams } from '../actions';
import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { MemberAreaClickActions } from '../../types';

export type ClickOnEmptyStateButton = () => void;

export function createClickOnEmptyStateButtonAction({
  getControllerState,
  context,
}: CreateActionParams): ClickOnEmptyStateButton {
  return async () => {
    const { flowAPI, biLogger } = context;
    const [state] = getControllerState();
    const { wixCodeApi } = flowAPI.controllerConfig;

    biLogger?.report(
      bookingsLoginUserAccountSeeAllServicesClick({
        action: MemberAreaClickActions.EmptyStateButton,
        referralInfo: state.selectedTab.type,
      }),
    );

    try {
      await navigateToServiceList({ wixCodeApi });
    } catch {
      wixCodeApi.location.to?.('/');
    }
  };
}
