import {
  ApplicationState,
  SettingsSubTab,
  SettingsTabPublicEvents,
} from '../../../types';
import {
  createEmptyDummyState,
  createUpcomingDummyState,
} from '../../createDummyState';
import { ActionsContext } from '../../../core/actionsContext/contextFactory';

export const mapConfigToState = (
  config: {
    [key: string]: any;
  },
  currentState: ApplicationState,
  context: ActionsContext,
): Partial<ApplicationState> => {
  const settingsEvent = config.publicData.COMPONENT.___settingsEvent;

  return {
    ...(settingsEvent
      ? mapEventToState(settingsEvent, currentState, context)
      : {}),
  };
};

function mapEventToState(
  {
    event,
    payload,
  }: {
    event: SettingsTabPublicEvents;
    payload: any;
  },
  currentState: ApplicationState,
  context: ActionsContext,
): Partial<ApplicationState> {
  let state: Partial<ApplicationState> = {
    settingsInfo: {
      selectedTab: currentState.settingsInfo?.selectedTab,
      selectedSubTab: currentState.settingsInfo?.selectedSubTab,
    },
  };

  switch (event) {
    case SettingsTabPublicEvents.SelectedTab:
      state.settingsInfo!.selectedTab = payload.value;
      break;
    case SettingsTabPublicEvents.SelectedSubTab:
      state.settingsInfo!.selectedSubTab = payload.value;
      break;
  }

  if (state.settingsInfo?.selectedSubTab === SettingsSubTab.NoBookings) {
    state = { ...state, ...createEmptyDummyState() };
  } else {
    state = { ...state, ...createUpcomingDummyState(context) };
  }

  return state;
}
