import { CancelMultiServiceBookingResponse } from '@wix/ambassador-bookings-v2-booking/types';
import { ApiContext } from '../../types';
import { cancelMultiServiceBooking } from '@wix/ambassador-bookings-v2-booking/http';

export interface CancelGroupArgs {
  groupId: string;
}

export const cancelGroup = async ({
  groupId,
  flowAPI,
}: CancelGroupArgs &
  ApiContext): Promise<CancelMultiServiceBookingResponse> => {
  const { httpClient } = flowAPI;
  return (
    await httpClient.request(
      cancelMultiServiceBooking({
        multiServiceBookingId: groupId,
        flowControlSettings: { withRefund: true },
      }),
    )
  ).data;
};
