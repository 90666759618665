import { ApiContext } from '../../types';
import { getCurrent } from '@wix/ambassador-identity-members-v1-member/http';

export const getCurrentUserContactId = async ({
  flowAPI,
}: ApiContext): Promise<string> => {
  const { httpClient } = flowAPI;
  return (
    (await httpClient.request(getCurrent({}))).data.member?.contactId || ''
  );
};
