import {
  BusinessInfo,
  BusinessTimezoneTypes,
  SelectedTimezone,
  UserInfo,
} from '../../../../types';

export interface GetSelectedTimezoneParams {
  businessInfo: BusinessInfo;
  userInfo?: UserInfo;
}

export const getSelectedTimezone = ({
  userInfo,
  businessInfo,
}: GetSelectedTimezoneParams): SelectedTimezone => {
  const { defaultTimezoneToUse } = businessInfo;

  return {
    timezone:
      defaultTimezoneToUse === BusinessTimezoneTypes.BUSINESS
        ? businessInfo.businessTimezone
        : userInfo?.timezone || '',
    type: defaultTimezoneToUse,
  };
};
