import { AllowedActions } from '../../src/types';

const validAllowedActions: AllowedActions = {
  cancel: true,
  reschedule: true,
  bookAnother: true,
};

export default function AllowedActionsBuilder() {
  const allowedActions = { ...validAllowedActions };

  return {
    build: () => allowedActions,
    withCancel(isAllowed = true) {
      allowedActions.cancel = isAllowed;
      return this;
    },
    withReschedule(isAllowed = true) {
      allowedActions.reschedule = isAllowed;
      return this;
    },
    withBookAnother(isAllowed = true) {
      allowedActions.bookAnother = isAllowed;
      return this;
    },
    buildWith: (obj?: Partial<AllowedActions>) => ({
      ...allowedActions,
      ...obj,
    }),
  };
}
