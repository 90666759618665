import { queryServices as getServices } from '@wix/ambassador-bookings-services-v2-service/http';
import {
  QueryServicesResponse,
  QueryServicesRequest,
  Service,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ApiContext } from '../../types';

export interface QueryServicesArgs {
  serviceIds: string[];
}

export const queryServices = async ({
  flowAPI,
  serviceIds,
}: QueryServicesArgs & ApiContext): Promise<Service[]> => {
  const { httpClient } = flowAPI;

  if (!serviceIds?.length) {
    return [];
  }

  const request: QueryServicesRequest = {
    query: {
      filter: {
        id: {
          $in: serviceIds,
        },
      },
      paging: {
        limit: 100,
      },
    },
  };
  const servicesResponse = (
    await httpClient.request<QueryServicesResponse>(getServices(request))
  ).data;

  return servicesResponse?.services || [];
};
