import {
  CancelClickOrigin,
  CancelModalClickValues,
  CloseModalTriggers,
} from '../../types';
import { CreateActionParams } from '../actions';
import { logCancelBi } from '../../api/implementations/logCancelBi/logCancelBi';

export type CloseModal = ({
  triggeredBy,
  origin,
  clickValue,
}: {
  triggeredBy?: CloseModalTriggers;
  origin?: CancelClickOrigin;
  clickValue?: CancelModalClickValues;
}) => void;

export function createCloseModalAction({
  context,
  getControllerState,
}: CreateActionParams): CloseModal {
  return ({
    triggeredBy,
    origin,
    clickValue = CancelModalClickValues.ExitModal,
  }) => {
    const [state, setState] = getControllerState();
    const { biLogger } = context;

    if (biLogger) {
      if (triggeredBy === CloseModalTriggers.CloseModal) {
        logCancelBi({
          origin,
          biLogger,
          selectedTab: state.selectedTab.type,
          clickValue,
        });
      }
    }

    setState({ modalContext: undefined });
  };
}
