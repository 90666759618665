import { StaffDTO } from '../../src/types';

const validStaff: StaffDTO = {
  id: '123-456',
  fullName: 'staff0',
};

export default function StaffBuilder() {
  const staffDTO = { ...validStaff };

  return {
    build: () => staffDTO,
    buildWithId(id: string) {
      staffDTO.id = id;
      return this;
    },
    buildWithName(name: string) {
      staffDTO.fullName = name;
      return this;
    },
  };
}
