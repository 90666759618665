import { CreateActionParams } from '../actions';
import { BookingDTO, MemberAreaClickActions } from '../../types';
import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-wixboost-ugc/v2';

export type ClickOnViewMoreDetails = (args: { booking: BookingDTO }) => void;

export function createClickOnViewMoreDetailsAction({
  getControllerState,
  context,
}: CreateActionParams): ClickOnViewMoreDetails {
  return async ({ booking }) => {
    const { biLogger } = context;
    const [state] = getControllerState();

    biLogger?.report(
      bookingsLoginUserAccountSeeAllServicesClick({
        bookingId: booking.bookingId,
        action: MemberAreaClickActions.ViewMoreDetails,
        extraInfo: JSON.stringify({
          serviceId: booking.service?.id,
        }),
        referralInfo: state.selectedTab.type,
      }),
    );
  };
}
