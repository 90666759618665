import { ApiContext } from '../../types';
import { FormattedFormSubmission } from '@wix/ambassador-forms-v4-submission/types';
import { getFormattedSubmission as getFormmattedSubmittionHttp } from '@wix/ambassador-forms-v4-submission/http';

export interface GetFormattedSubmissionArgs {
  submissionId: string;
}

export const getFormattedSubmission = async ({
  submissionId,
  flowAPI,
}: GetFormattedSubmissionArgs & ApiContext): Promise<
  FormattedFormSubmission | undefined
> => {
  const { httpClient } = flowAPI;
  return (
    await httpClient.request(getFormmattedSubmittionHttp({ submissionId }))
  ).data;
};
