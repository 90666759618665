import { BusinessTimezoneTypes, SelectedTimezone } from '../../src/types';

export const mockSelectedTimezone = ({
  type = BusinessTimezoneTypes.BUSINESS,
  timezone = 'America/Mexico_City',
}: Partial<SelectedTimezone> = {}): SelectedTimezone => {
  return {
    type,
    timezone,
  };
};
