import { defaultLocale } from '../../../../consts/locale';
import { ActionsContext } from '../../../../core/actionsContext/contextFactory';
import { UserInfo } from '../../../../types';

export const getUserInfo = async ({
  flowAPI,
  api,
}: ActionsContext): Promise<UserInfo> => {
  const locale =
    flowAPI?.controllerConfig?.wixCodeApi?.window?.locale || defaultLocale;
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

  const user = flowAPI?.controllerConfig.wixCodeApi.user.currentUser;

  let contactId = user.id;

  if (user.role === 'Admin') {
    try {
      contactId = await api.getCurrentUserContactId() || user.id;
    } catch (e) {
      console.error('Failed to get current user contact id', e)
    }
  }

  return { locale, timezone, contactId };
};
