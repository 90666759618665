import { BookingsGroup } from '../../src/types';
import AllowedActionsBuilder from './mockAllowedActions';
import BookingBuilder from './mockBooking';

export const mockGroup = ({
  allowedActions = AllowedActionsBuilder().build(),
  bookings = [BookingBuilder().withStaffInfo().withService().build()],
  groupId = 'some-group-id',
  order,
}: Partial<BookingsGroup> = {}): BookingsGroup => {
  if (bookings?.length === 1) {
    groupId = bookings[0].bookingId;
  }
  return {
    allowedActions,
    bookings,
    groupId,
    ...(order ? { order } : {}),
  };
};
