export enum SettingsTabPublicEvents {
  SelectedTab = 'selectedTab',
  SelectedSubTab = 'selectedSubTab',
}

export enum SettingsTab {
  Main = 'main',
  Design = 'design',
  Text = 'text',
}

export enum SettingsSubTab {
  General = 'general',
  WithBookings = 'withBookings',
  NoBookings = 'noBookings',
}

export enum TabDesignSubTab {
  Regular = 'regular',
  Selected = 'selected',
}

export interface SettingsInfo {
  selectedTab?: SettingsTab;
  selectedSubTab?: SettingsSubTab;
}
