import { CreateActionParams } from '../actions';

export type CloseToast = () => void;

export function createCloseToastAction({
  getControllerState,
}: CreateActionParams): CloseToast {
  return () => {
    const [, setState] = getControllerState();

    setState({ toastContext: undefined });
  };
}
