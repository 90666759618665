import { ApiContext } from '../../types';
import { queryOrders } from '@wix/ambassador-ecom-v1-order/http';
import { QueryOrderResponse } from '@wix/ambassador-ecom-v1-order/types';

export interface QueryEcomOrdersArgs {
  bookingIds: string[];
}

export const queryEcomOrders = async ({
  bookingIds,
  flowAPI,
}: QueryEcomOrdersArgs & ApiContext): Promise<QueryOrderResponse> => {
  const { httpClient } = flowAPI;
  return (
    await httpClient.request(
      queryOrders({
        query: {
          filter: {
            'lineItems.catalogReference.catalogItemId': { $in: bookingIds },
          },
        },
      }),
    )
  ).data;
};
