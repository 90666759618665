import { queryExtendedBookings } from '@wix/ambassador-bookings-reader-v2-extended-booking/http';
import { ApiContext } from '../../types';

export interface GetBookingByIdArgs {
  bookingId: string;
}

export const getBookingById = async ({
  bookingId,
  flowAPI,
}: GetBookingByIdArgs & ApiContext) => {
  const { httpClient } = flowAPI;

  return (
    await httpClient.request(
      queryExtendedBookings({ query: { filter: { id: bookingId } } }),
    )
  ).data;
};
