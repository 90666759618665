import { EventNames } from '../../core/fedops';
import {
  BookingsGroup,
  CancelModalClickValues,
  CancelClickOrigin,
  ToastContext,
  Bookings,
} from '../../types';
import { CreateActionParams } from '../actions';
import _ from 'lodash';
import { BookingStatus } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { Order } from '@wix/ambassador-ecom-v1-order/types';
import { BOOKINGS_APP_DEF_ID } from '../../consts/applicationIdentities';

export type ClickOnCancel = ({
  group,
  origin,
}: {
  group: BookingsGroup;
  origin?: CancelClickOrigin;
}) => void;

const isOrderHasMoreThanOneService = (order: Order): boolean => {
  return (
    (order?.lineItems?.filter(
      (lineItem) => lineItem?.catalogReference?.appId === BOOKINGS_APP_DEF_ID,
    )?.length || 0) > 1
  );
};

export function createClickOnCancelAction({
  context,
  getControllerState,
}: CreateActionParams): ClickOnCancel {
  return async ({ group, origin }) => {
    const { api, flowAPI, fedopsLogger, biLogger } = context;
    const [state, setState] = getControllerState();
    const { t } = flowAPI.translations;
    let totalCanceledBookings = 1;
    if (biLogger) {
      api.logCancelBi({
        biLogger,
        selectedTab: state.selectedTab.type,
        clickValue: CancelModalClickValues.Cancel,
        confirmation: true,
        origin,
      });
    }

    fedopsLogger?.interactionStarted(EventNames.Cancel);
    const upcomingData = _.cloneDeep(state.upcomingData);
    const groups = state.upcomingData?.groups || [];

    const modifiedGroups: BookingsGroup[] = groups.filter((bookingGroup) => {
      if (group.groupId) {
        return bookingGroup.groupId !== group.groupId;
      } else {
        return (
          bookingGroup.bookings[0].bookingId !== group.bookings[0].bookingId
        );
      }
    });

    setState({
      upcomingData: {
        ...state.upcomingData,
        groups: modifiedGroups,
      },
    });

    try {
      if (group.groupId) {
        // cancel multi services
        await api.cancelGroup({ groupId: group.groupId });
        totalCanceledBookings = group.bookings.length;
      } else {
        // cancel single service
        const booking = group.bookings[0];
        const bookingId = booking.bookingId;
        if (booking?.status === BookingStatus.WAITING_LIST) {
          // cancel service in wait list
          await api.cancelWaitlist({
            bookingId,
            sessionId: booking?.sessionId!,
          });
        } else {
          const { order } = group;

          const shouldUpdateBookingRevision =
            origin === CancelClickOrigin.CancellationFeeModal &&
            order &&
            isOrderHasMoreThanOneService(order);

          if (shouldUpdateBookingRevision) {
            const updatedBooking = await api.getBookingById({ bookingId });
            booking.revision = updatedBooking.extendedBookings?.find(
              (extendedBooking) => extendedBooking.booking?.id === bookingId,
            )?.booking?.revision;
          }

          await api.cancelSingle({
            bookingId,
            bookingRevision: booking.revision,
          });
        }
      }

      const pagingMetadata = state.upcomingData?.pagingMetadata!;
      const stateToSet: {
        toastContext: ToastContext;
        upcomingData?: Bookings;
      } = {
        toastContext: {
          skin: 'success' as any,
          content: t(
            'app.my-bookings-widget.cancellation-modal.toast-success-message.label',
          ),
        },
      };

      if (pagingMetadata?.hasNext) {
        pagingMetadata.offset = pagingMetadata.offset! - totalCanceledBookings;
        stateToSet.upcomingData = {
          groups: modifiedGroups,
          pagingMetadata: state.upcomingData?.pagingMetadata,
        };
      }

      setState(stateToSet);
      fedopsLogger?.interactionEnded(EventNames.Cancel);
    } catch (e) {
      setState({
        upcomingData,
        toastContext: {
          skin: 'error' as any,
          content: t(
            'app.my-bookings-widget.cancellation-modal.toast-error-message.label',
          ),
        },
      });
    } finally {
      setTimeout(
        () =>
          setState({
            toastContext: undefined,
          }),
        5000,
      );
    }
  };
}
