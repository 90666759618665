import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export enum EventNames {
  Cancel = 'cancel-booking',
  UpcomingTabLoaded = 'upcoming-tab-loaded',
  HistoryTabLoaded = 'history-tab-loaded',
  PageLoaded = 'page-loaded',
  WidgetInitialized = 'widget-initialized',
}

export class FedopsLogger {
  constructor(private flowApi: ControllerFlowAPI) {}

  interactionStarted(eventName: EventNames) {
    this.flowApi.fedops.interactionStarted(eventName.toString());
  }

  interactionEnded(eventName: EventNames) {
    this.flowApi.fedops.interactionEnded(eventName.toString());
  }
}
