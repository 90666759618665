import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  CancelClickOrigin,
  CancelModalClickValues,
  MemberAreaClickActions,
  TabTypes,
} from '../../../types';
import { BiLogger } from '../../../core/bi/biLoggerFactory';

export interface LogCancelBiArgs {
  biLogger: BiLogger;
  selectedTab: TabTypes;
  clickValue: CancelModalClickValues;
  confirmation?: boolean;
  origin?: CancelClickOrigin;
}

export const logCancelBi = ({
  biLogger,
  selectedTab,
  clickValue,
  confirmation = false,
  origin,
}: LogCancelBiArgs) => {
  biLogger?.report(
    bookingsLoginUserAccountSeeAllServicesClick({
      action: MemberAreaClickActions.Cancel,
      extraInfo: JSON.stringify({
        ...(origin ? { origin } : {}),
        confirmation,
        clickValue,
      }),
      referralInfo: selectedTab,
    }),
  );
};
