import { list } from '@wix/ambassador-bookings-catalog-v1-resource/http';
import { ApiContext } from '../../types';
import { ListResourcesResponse } from '@wix/ambassador-bookings-catalog-v1-resource/types';

export interface ListResourcesByTagNameArgs {
  resourceTagName: string;
}

export const listResourcesByTagName = async ({
  resourceTagName,
  flowAPI,
}: ListResourcesByTagNameArgs & ApiContext): Promise<ListResourcesResponse> => {
  const { httpClient } = flowAPI;
  return (
    await httpClient.request(
      list({
        query: {
          filter: {
            $and: [
              { 'resource.tags': { $hasSome: [resourceTagName] } },
              {
                'resource.status': {
                  $hasSome: ['CREATED', 'UPDATED', 'DELETED'],
                },
              },
            ],
          },
          fields: undefined,
          fieldsets: undefined,
          sort: undefined,
        },
      }),
    )
  ).data;
};
